<template>
    <div >
        <div v-show="this.oncharge==false" >
            <vs-row  vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
                <vs-col   vs-align="center" vs-xs="12" vs-sm="12" vs-lg="5">
                        <StatMemberMonth ref="statTransfMonth"  v-bind:datee="select_date" @updateCharge="updateCharge" ></StatMemberMonth>
                    </vs-col>
                    <vs-col   vs-align="center"  vs-xs="12" vs-sm="12" vs-lg="7">
                        <StatMemberYear ref="statTransfYear"  v-bind:datee="select_date" ></StatMemberYear>
                    </vs-col>
                    
            </vs-row>
        </div>
        <div v-show="this.oncharge">
            <vx-card>
                <wait/>
            </vx-card>
        </div >
    </div>
</template>

<script>
import StatMemberMonth from '@/components/stats/components/graphAccueil/statTransfMonth.vue'
import StatMemberYear from '@/components/stats/components/graphAccueil/statTransfYear.vue'

import wait from '@/components/stats/components/graphAccueil/waiting.vue'


export default {
    components:{
        StatMemberMonth,
        StatMemberYear,
        wait
    },
    data(){
        return{
            oncharge:false,
            select_date:new Date()
        }
    },
    watch:{
            oncharge:function(){
                // console.log("this. ", this.oncharge)
            }
    },
    methods:{
         UpdateStatTransformationMonth(select_date){
             this.select_date=select_date
            this.$refs.statTransfMonth.setDate( this.select_date)
            this.$refs.statTransfYear.setDate( this.select_date)
        },

        UpdateTaux(date){
            this.select_date=date;
            this.$refs.statTransfMonth.refresh()
            this.$refs.statTransfYear.refresh()
        },

        updateCharge(val){
           
            this.oncharge=val;
        },
        
        
        
    }
}
</script>

<style>

</style>