<template>
    <vx-card class="graphDonutBooking">
        <vs-row>
            <vs-col  vs-xs="12" vs-sm="12" vs-lg="4" class="container">
                
                <div class="donut_marge"></div>
                <vs-row>
                    <vs-col vs-w="10">
                        <h2 class="text-left nowrap">Semaine -1:</h2>
                    </vs-col>
                    <vs-col vs-w="2">
                        <div class="text-right h1">{{ nb_m1 }}</div>
                    </vs-col>
                </vs-row>
                <vs-divider/>

                <GraphDonut ref="semaine_1" height="150"/>
            </vs-col>

            <vs-col vs-xs="12" vs-sm="12" vs-lg="4" class="container">

                <vs-row>
                    <vs-col vs-w="10">
                        <h2 class="text-left nowrap">Semaine:</h2>
                    </vs-col>
                    <vs-col vs-w="2">
                        <div class="text-right h1">{{ nb_1 }}</div>
                    </vs-col>
                </vs-row>

                <vs-divider/>
                <GraphDonut ref="semaine0" height="250"/>
            </vs-col>
            <vs-col vs-xs="12" vs-sm="12" vs-lg="4" class="container">
                
                <div class="donut_marge"></div>
                <vs-row>
                    <vs-col vs-w="10">
                        <h2 class="text-left nowrap">Semaine +1:</h2>
                    </vs-col>
                    <vs-col vs-w="2">
                        <div class="text-right h1">{{ nb_p1 }}</div>
                    </vs-col>
                </vs-row>

                <vs-divider/>
                <GraphDonut ref="semaine1" height="150"/>
            </vs-col>
        </vs-row>
    </vx-card>
</template>

<style lang="scss">
.graphDonutBooking{
    @media only screen and (max-width: 300px)
    {
        .vx-card__body{
            padding:0px !important;
        }
    }
    @media only screen and (min-width: 899px)
    {
        .donut_marge{
            height:50px;
        }
        .container{
            padding-right:50px;
            padding-left:50px;
        }
    }
}
</style>

<script>
import GraphDonut from '@/components/stats/components/DonutBooking.vue';
import Booking from '@/database/models/booking'
import Fdate from '@/assets/utils/fdate'

export default {
    components:{
        GraphDonut,
	},
    props: {

    },
    data(){
        return{
            nb_m1:0,
            nb_1:0,
            nb_p1:0

        }
    },
    watch:{
        $route ()
        {
            this.refresh();
        },
    },
    mounted:function()
	{
        this.refresh();
    },
    methods:{
        refresh()
        {
            Booking.getTabAllMemory((list)=>
            {
                let ts_si_1 = this.Monday( -1 ).getTime();
                let ts_si_0 = this.Monday(  0 ).getTime();
                let ts_si_2 = this.Monday(  1 ).getTime();

                let ts_sa_1 = (new Date(ts_si_1)).setDate( (new Date(ts_si_1)).getDate()+7 );
                let ts_sa_0 = (new Date(ts_si_0)).setDate( (new Date(ts_si_0)).getDate()+7 );
                let ts_sa_2 = (new Date(ts_si_2)).setDate( (new Date(ts_si_2)).getDate()+7 );

                //console.log( new Date(ts_si_0),'---',  new Date(ts_sa_0) )

                for( var i=0; i<list.length; i++ )
                {
                    //console.log( list[i] );
                    if(list[i].type != "b")
                    {
                        var buffer = list[i];
                        var ts = (new Date( buffer.date )).getTime();

                        //semaine -1
                        if( ts > ts_si_1 )
                        if( ts < ts_sa_1 )
                        {
                            if( this.Add( buffer, this.$refs.semaine_1 ))
                                this.nb_m1++;
                        }
                        
                        //semaine 0
                        if( ts > ts_si_0 )
                        if( ts < ts_sa_0 )
                        {
                            if( this.Add( buffer, this.$refs.semaine0 ))
                                this.nb_1++;
                        }

                        //semaine 1
                        if( ts > ts_si_2 )
                        if( ts < ts_sa_2 )
                        {
                            if( this.Add( buffer, this.$refs.semaine1 ))
                                this.nb_p1++;
                        }   
                    }
                }

                if(this.$refs.semaine_1)
                {
                    this.$refs.semaine_1.refresh();
                    this.$refs.semaine0.refresh();
                    this.$refs.semaine1.refresh();
                }
            });
        },


        Monday( posi )
        {
            let d = new Date();
            switch(posi)
            {
                case -1:
                    d = new Date( Fdate.getMonday( (new Date() ).setDate( (new Date()).getDate()-7 ) ));
                break;

                case 0:
                    d = new Date( Fdate.getMonday( new Date() ));
                break;

                case 1:
                    d = new Date( Fdate.getMonday( (new Date()).setDate( (new Date()).getDate()+7 ) ));
                break;
            }

            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            return d;
        },


        Add( data, link )
        {
            let value = false;
            if(link)
            switch( data.type )
            {
                case 'e':
                    link.addEssai( 1 );
                    value=true;
                break;
                case 'c':
                    link.addContractuel( 1 );
                    value=true;
                break;
                case 's':
                    link.addMember( 1 );
                    value=true;
                break;
            }
            return value;
        }
    }
}

</script>