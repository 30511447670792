<template>

    <vx-card>
        <h2> Séances dispensées {{ tyear }}</h2>
        <br/>
          <vue-apex-charts ref="charts" type="line" height="350" :options="mixedChart.chartOptions" :series="mixedChart.series"></vue-apex-charts>

    </vx-card>
</template>

<style lang="scss" scoped>

</style>

<script>
import VueApexCharts from 'vue-apexcharts'
import Member from '@/database/models/member'
import Booking from '@/database/models/booking'

export default {
    components:{
        VueApexCharts
    },
    props:['year'],
    data(){
    return {
      tyear : this.year,

      themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
      mixedChart: {
        series: [{
            name: 'Essai',
            type: 'column',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }, {
            name: 'Séance',
            type: 'column',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }, {
            name:'Total',
            type:'line',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          
          ],
        chartOptions: {
          colors: [ Member.color.essai, Member.color.actif ,'#7367F0'],
          chart: {
            stacked: false,
          },
          stroke: {
            width: [5, 5, 2],
            curve: 'smooth'
          },
          plotOptions: {
            bar: {
              columnWidth: '50%'
            }
          },

          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: 'light',
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100]
            }
          },
          labels: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
          markers: {
            size: 0
          },
          xaxis: {
            type: 'month'
          },
          yaxis: {
            title: {
              text: '',
            },
            min: 0
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function(y) {
                if (typeof y !== "undefined") {
                  return y.toFixed(0) + " séance(s)";
                }
                return y;

              }
            }
          }
        }
      }

    }
  },
  mounted()
  {
      this.update();
  },
  methods:{
      update()
      {
        //this.columnChart.series[0].data[2] = 55
        Booking.getTabAllMemory((list)=>
        {
            //reset
            this.mixedChart.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            this.mixedChart.series[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]


            for( var i=0; i<list.length; i++)
            {
                var buffer = list[i];
                var da = new Date(buffer.date)
                if( da.getFullYear() == this.tyear )
                {
                    let month = da.getMonth();
                    switch (buffer.type)
                    {
                        case 'e':
                          // console.log(" -- plomb : ", buffer.member)
                            this.mixedChart.series[0].data[month]++;
                        break;
                        case 's':
                            this.mixedChart.series[1].data[month]++;
                        break;
                    }
                }
            }
            let c =[];
            for (let i=0; i<this.mixedChart.series[0].data.length; i++){
              let aux = this.mixedChart.series[0].data[i]+this.mixedChart.series[1].data[i]
                c.push(aux);
            }
            
            this.mixedChart.series[2].data=c;
            this.$refs.charts.refresh();
        });
      },


    setYear( value )
    {
        this.tyear = value
    }

  }
}
</script>