<template>
    <vx-card class="card_adjust">
        <h2>Notifications</h2>
        <br/>
        <SMSnb style="text-align:left;"/>
    </vx-card>
</template>

<style lang="scss" scoped>
@media only screen and (min-width: 900px)
{
    .card_adjust{
        margin-left:10px;
    }
}
@media only screen and (max-width: 899px)
{
    .card_adjust{
        margin-left:0px;
        margin-top:20px;
    }
}

</style>

<script>

import SMSnb from '@/components/communication/sms_view.vue'


export default {
    components:{
        SMSnb,
	},
    props: {

    },
    data(){
        return{

        }
    },
    watch:{
        $route (){
            
        },
    },
    mounted:function()
	{

    },
    methods:{
 
    }
}

</script>