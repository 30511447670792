<template>

  <div>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous">

    <vx-card class="card_adjust">
      <!-- <template v-if="this.oncharge==false"> -->
        <vs-row>
          <vs-col vs-w="11">
            <h2> Taux de transformation  {{ this.month }} </h2>
          </vs-col>
          <vs-col vs-w="1">
            <vs-icon icon="info" size="small" bg="primary"  color="white" round  @click="getInfo=true;"></vs-icon>
            
          </vs-col>
          <vs-popup  title="Informations Complémentaires" :active.sync="getInfo" >
            <h3 class="pop " > Informations</h3>
            <br/>
            <p class="popT">Appuyer sur le bouton "Mise à jour" pour charger les données.
              </p>
            
            <vs-divider/>

            <h3 class="pop " > Méthode</h3>
            <br/>
              <p class="popT"> Les transformations de séances d'essai sont comptabilisées le mois de la signature du contrat.</p>
            
            <vs-divider/>

            <h3 class="pop "> Exemple</h3>
            <br/>
            <p class="popT"> Un client effectue une séance d'essai le 15 janvier.<br/>
            Il achète des séances en mars.<br/>
            La transformation sera appliquée en mars.</p>

            <vs-divider/>

            <h3 class="pop"> Calcul du taux de transformation Annuel </h3>
            <br/>
            
            <p class="popT justify" > 
              Pour ce calcul, les essais inclus : les prospects inscrits via 
              le menu membre + ceux inscrits directement dans le planning. <br/>
              
              <br/>
              Membre essai : {{ this.nbYess }} <br/>
              Membre transformé : {{ this.nbYm }} <br/>
              
              Ici, le taux de transformation annuel : ( {{ this.nbYm }} / {{ this.nbYess }} ) * 100 = {{ this.moyenne }} % 
            </p>

          </vs-popup>
        </vs-row>
        <VueApexCharts type="radialBar" height="350" :options="radialBarChart.chartOptions" :series="radialBarChart.series"></VueApexCharts>
        <br/>
        <div>
          <table align="center">
            <th align="center" style=" color:#85C1E9;   " class="leftB butt" @click="getEssai=true;" > Essai </th>
            <th align="center" style="color:#58D68D; " class="leftB butt" @click="getEssai=true;"> Transformations  &lt;30 jours </th>
            <th align="center" style="color:#5b3cc4;" class="butt" @click="getEssai=true;"> Transformations  &gt;30 jours </th>
            
            <tr align="center">
              <td style=" color:#85C1E9; " class="leftB"> {{ this.nbrEss }} </td>
              <td style="color:#58D68D; " class="leftB"> {{ this.nbrEssMbrN }} </td>
              <td style="color:#5b3cc4;"> {{ this.nbrEssMbrB }} </td>

            </tr>

          </table>
        </div>


          
          
          <br/>

        
                    
            <vs-popup title="Membres Essais" :active.sync="getEssai"   background-color="rgba(255,255,255,.6)" >
                          
            <div class="panel_all_member">            
                    <!--  Table ::  -->
                    <vs-table  class="vs-popup--content" multiple v-model="selected" pagination max-items="7" search :data="allNew" noDataText="Aucun résultat" >
                          
                          <!-- header template -->
                          <template slot="header">
                              <div class="dropdown-button-container">
                                <vs-button class="btnx nowrap" type="flat" v-on:click="mbrAllSelect()">{{ getBtnSelectName() }}</vs-button>
                                <vs-button class="btnx2 nowrap" type="flat" v-on:click="mbrSelect()" v-bind:style="getBtnSelectEffet()" >{{ selected.length }}</vs-button>
                                
                                <template >
                                  <!-- <vs-button class="btn-drop cursor" type="flat" icon="menu" >  -->
                                    <v-select  class="btn-drop cursor "  :options="[ 'Envoyer SMS', 'Envoyer Email']"  @input="changed" />
                                      
                                      
                                    <!-- </vs-button> -->
                                </template>
                                
                                
                              </div>
                          </template>

                          <!-- header table -->
                          <template slot="thead" class="thead">
                            
                            <vs-th sort-key="first_name">Prénom Nom</vs-th>
                            <vs-th sort-key="phone">Téléphone</vs-th>
                            
                            <vs-th > Etat  </vs-th>
                            <!-- <vs-th sort-key="credit"> Motif(s) </vs-th> -->
                          </template>

                          <!--Body table-->
                          <template slot-scope="{data}">
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                            
                              <vs-td :data="data[indextr].first_name" >
                                <span @click="getEssai=false; handleSelected(tr);">{{ data[indextr].first_name+' '+data[indextr].last_name }}</span>
                              </vs-td>

                              <vs-td :data="data[indextr].phone">
                                <span @click="getEssai=false; handleSelected(tr);">{{ getPhoneClean(data[indextr].phone) }}</span>
                              </vs-td>

                              

                              <vs-td :data="data[indextr].uid" >

                                <template v-if="listMember[data[indextr].uid].type=='e'">
                                  <span @click="getEssai=false; handleSelected(tr);" class="text-spanR" >  Non transformé </span>
                                </template>
                              
                                <template v-if="getIndex(listNewMemberB,data[indextr].uid)==true">
                                  <span @click="getEssai=false; handleSelected(tr);" class="text-spanG"> Transformé &gt;30 jours </span>
                                </template>

                                <template v-if="getIndex(listNewMember,data[indextr].uid)==true">
                                  <span @click="getEssai=false; handleSelected(tr);" class="text-spanG"> Transformé &lt;30 jours </span>
                                </template>

                              </vs-td>
                              <!-- <vs-td :data="data[indextr].uid">
                                <template v-if="listMember[data[indextr].uid].type=='e'">
                                  <select v-model="why">
                                    <option disabled value="" >Motif</option>
                                    <option>Mauvaise qualité de services </option>
                                    <option>Pas à son goût </option>
                                    <option>À trouver mieux ailleurs</option>
                                  </select><p></p>
                                </template>
                              </vs-td> -->

                              


                            </vs-tr>
                          </template> 
                        </vs-table>


                      
            </div>
            </vs-popup>
          <br/>
        <!-- <vs-button @click="refresh()"> Mettre à jour  </vs-button> -->
      <!-- </template> -->
      <!-- <template v-else>
        <wait/>
        <p> chargement ... </p>
      </template> -->
    </vx-card>
  </div>
</template>


<script>

import Member from '@/database/models/member'
import Item from '@/database/models/item'
// import Invoice from '@/database/models/invoice'
import Phone from '@/assets/utils/phone'

import wait from './waiting'

import autoRefresh from '@/assets/utils/autoRefresh'

import moment from 'moment'
import VxCard from '@/components/vx-card/VxCard.vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'

export default {
  props:{
    datee:"",
  },
  components:{
    Member,
    autoRefresh,
    Item,
    VxCard,
    Phone,
    'v-select': vSelect,
    VueApexCharts,
    wait 
    
  },
  watch:{
      oncharge:function(){
        // console.log(' emit ', this.oncharge)
        //this.$emit('updateCharge', this.oncharge)
      },
      getEssai:function(){
      },
      $route(){
        this.getEssai=false;
      }
  },
  data() {
    return {
      monthNames : ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin",
          "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"
        ],
      month:"",
      nbrNewMbr :0,
      nbrEss : 0,
      nbrEssMbrN :0,
      nbrEssMbrB :0,
      listMember :{},
      listNewMemberB :[],

      oncharge:false,

      listTr:[],
      listNTr:[],

      allNew:[],
      getInfo:false,
      
			buffer_mbr_etat: false,
      go:false,

      date:this.datee,
      listItem:{},

      


      tablepourcentage:[],
      listNewMember:[],
      listMemberEssai:[],
      default:0,
      moyenne:0,
      nbYess:0,
      nbYm:0,
      cal:false,

      called : false, // permet de savoir si la fonction mise à jour a deja été appelé 

      getEssai:false,
      selected:[],
      why:"",
      admin:false,

      //  pour le graphe : il manque à en selectionné un d'offoice 

      radialBarChart: {
        series: [],
        chartOptions: {
          colors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
          plotOptions: {
            radialBar: {
              
              dataLabels: {
                
                name: {
                  show:true,
                  fontSize: '22px',
                },
                value: {
                  show:true,
                  fontSize: '22px',
                },
                total: {
                  show: true,
                  label: 'Cette Année',
                  color:'#7367F0',
                  formatter: function(w) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    
                    this.label=w.config.labels[0];
                    return w.config.series[0] + "% "
                  }
                },
                
  

                
                
                
              }
            }
          },
          labels: [],
        }
      }}
  },
 
  
  mounted:function(){
    // autoRefresh.SetCallback(async()=>
    //     {
    //       this.reinitialize()
    //       this.RoleAdmin();
    //       this.listMember = await Member.AwaitGetAllMemory();
    //       this.listItem = await Item.AwaitGetAllMemory();
          
    //       this.month = this.monthNames[this.date.getMonth()];
    //       this.getAnnuelNumber(true);
    //         await this.getNewMember();
          
           
    //     })
    //     autoRefresh.refresh();
    
  },
  methods:{
    refresh(){
       autoRefresh.SetCallback(async()=>
        {
          this.called=true;
          this.reinitialize()
          this.reinitialize2()
          this.RoleAdmin();
          this.listMember = await Member.AwaitGetAllMemory();
          this.listItem = await Item.AwaitGetAllMemory();
          
          this.month = this.monthNames[this.date.getMonth()];
            await this.getAnnuelNumber(true);
            await this.getNewMember();
          
           
        })
        autoRefresh.refresh();
    },
    handleSelected(tr){
        this.getEssai=false;
        this.selectMbr(tr.uid)
       
    },
    changed(event){
     this.getEssai=false;
      if ( event =="Envoyer SMS")
        this.SendSMS();
      else if ( event =='Envoyer Email')
        this.SendEmail();
      
      
    },
    getPhoneClean( phone )
		{
			return Phone.getLocalPhone( phone );
		},
    // À utiliser que pour la table
    getIndex(list,x){
            let d =false;
            // permet de recuper l'index de ce qui est chercher
            for (let i in list){
                if(list[i].uid==x){
                    
                    return d=true;
                }
            }
            return d;
      },
    getBtnSelectEffet()
		{
			if(this.buffer_mbr_etat)
				return { 'background-color':'#493cc9 !important' };
		},
    getBtnSelectName()
		{
			if(this.selected.length>0)
				return 'Désélectionner';
			return 'Tout séléctionner';
		},
    mbrSelect()
		{
			//Desaffiché que les selectionné
			if( this.buffer_mbr_etat == true)
			{
				this.buffer_mbr_etat = false;
			}
			else
			//afficher les selectionné
			{
				this.buffer_mbr_etat = true;
			}
		}, 
    mbrAllSelect()
		{
			//tout selectionné
			if(this.selected.length==0)
				return this.selected = this.allNew;
			if(this.selected.length!=0)
				return this.selected = [];
		},
    selectMbr( uid )
		{
      this.getEssai=false;
			this.$router.push('/admin/member/'+uid);
		},
    RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            
            

            Member.getMemory(uid, (mbr)=>
            {
              
                this.member_type = mbr.type.toLowerCase()
                if (mbr.type.toLowerCase() == "a"){
                  this.admin = true;
                  
                }
                  
            });
        },
    async setDate(date){
      
      autoRefresh.SetCallback(async()=>
        {
          this.reinitialize()
          if( this.date.getFullYear()!= date.getFullYear()  && this.called==true){
            this.cal=true;
            this.date=date;
            this.month = this.monthNames[this.date.getMonth()];
            await this.getNewMember();
          }
          else if (this.called==false){
            this.date=date;
            
            await this.refresh()
          }
          else{ 
              this.cal=false;
              this.date=date;
            this.month = this.monthNames[this.date.getMonth()];
            await this.getNewMember();
          }
        
          
           
        })
        autoRefresh.refresh();
       
      
      
    },
    SendSMS(){
			window.api_selected = this.selected;
			this.$router.push('/admin/sms/groupe');
		},
		isPageCampagne()
		{
			return this.$router.currentRoute.path != "/admin/member"
		},

		SendEmail(){
			window.api_selected = this.selected;
			this.$router.push('/admin/email/groupe');
		},
    reinitialize(){
      this.nbrEss=0;
      this.nbrNewMbr=0;
      this.nbrEssMbrN=0;
      this.nbrEssMbrB=0;
      this.getEssai=false;
      this.selected=[];
      this.listNewMemberB=[];
      this.listMemberEssai=[];
      this.listNewMember=[];
      this.nbYess=0;
      this.nbYm=0;
      
    },
    reinitialize2(){
      
      this.nbYess=0;
      this.nbYm=0;
      
    },

    // permet de calculer le nbr de membre issu des essais, ainsi que 
    // le nbr d'essai
    async getNewMember(){
      // this.oncharge=true;
      this.changedcharge(true);
      // this.$emit('updateCharge', this.oncharge)
       // this.reinitialize()
        this.tablepourcentage=[];

        // avoir le premier jour du mois 
        let firstday = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
        let deb = moment(firstday,"").format("YYYY-MM-DD")

        // avoir le dernier jour du mois 
        let lastDay = new Date(this.date.getFullYear(), this.date.getMonth()+1, 0);
        let fin = moment(lastDay,"").format("YYYY-MM-DD")
        
        
        // pour chaque membre 
        for (let i in this.listMember){
          let aux = this.listMember[i];

          
          // je recup la date de creation du membre 
          let creerAt = moment(aux.createAt,"").format("YYYY-MM-DD");

          // et je lance la fonction qui me permet de savoir si le membre est issus d'un 
          // mois précédent ou pas
          let  memberAt = await this.getFirstFact(aux.uid, creerAt, aux.createAt,deb,  fin)
          
          // si la date memberAt != null, cela signifie qu'il s'agit d'une transformation
          // si la date de memberAt est dans la date demander 
          // alors il en fait partie
          if ( memberAt != null ){
            // si c'est une transformation && que la date de transformation correspond 
             if (moment(memberAt).isBetween(deb.toString(),fin.toString()) || moment(memberAt).isSame(deb.toString()) || moment(memberAt).isSame(fin.toString())){
                  //|| ++;
                  this.nbrNewMbr++;
                  this.nbrEss++;
                  
                  
                
             }
             // si il y a eu transformation et que la date est 
            // superieur a celle demander alors c'était un essai 
             else if (moment(memberAt).isBefore(aux.createAt) || moment(memberAt).isSame(aux.createAt)){
                this.nbrEss++; 
                this.nbrNewMbr++;
             }
          }
          else{
            if( moment(creerAt.toString()).isBetween(deb.toString(),fin.toString()) || moment(creerAt.toString()).isSame(deb.toString()) || moment(creerAt.toString()).isSame(fin.toString()) ){
                if( aux.type=="e"){
                  this.nbrEss++;
                  this.nbrNewMbr++;
                  this.listMemberEssai.push(aux);
                }
                else if (aux.type=="m"){
                  this.nbrNewMbr++;
                }
            }
          }

          
        
        
        }


        await this.fixeData()
        let x = this.listNewMemberB.concat(this.listNewMember)
        this.allNew = x.concat(this.listMemberEssai)
        // 
         
      },
       async getFirstFact(uid, date, creat, deb, fin){
          
          var d = fin;
          var factFirst={};
          var changed = false; 
        
       

          // on passe en revu chaque facture 
          // for(let i in this.listItem){
          //   let aux = this.listItem[i];
          //   // on recupere les factures de chaque individus 
          //   if ( aux.member == uid && aux.credit >0){
          //       let create = moment(aux.createAt,"").format("YYYY-MM-DD")
          //       // on regarde si la date de la facture correspont à la premiere facture 
          //       if ( moment(create.toString()).isBefore(d) || moment(create.toString()).isSame(d)  ){
          //           changed=true;
          //           d= create.toString();
          //           factFirst=aux;
          //       }

          //   }
           
          // }

        
          // let firstt = await Invoice.AwaitGetAllMemoryKey(uid)
          let first = await Item.AwaitGetAllMemoryKey(uid)
          if (first){
          
            for ( let i in first){
              let j = new Date(first[i].createAt).getDate()
              let m = new Date(first[i].createAt).getMonth()+1
              let y = new Date(first[i].createAt).getFullYear()
              
              let createa = y+"-"+m+"-"+j
              let create = moment(createa,"YYYY-MM-DD").format("YYYY-MM-DD")
                  // on regarde si la date de la facture correspont à la premiere facture 
                  if ( moment(create.toString()).isBefore(d) || moment(create.toString()).isSame(d)  ){
                      
                      changed=true;
                      d= create.toString();
                      factFirst=first[i];
                    
                  }
                  
                  
                  
            }
          }
          
          
          // date dans l'écart demander 
          if ( moment(d).isBetween(deb.toString(), fin.toString()) || moment(d).isSame(deb.toString()) || moment(d).isSame(fin.toString()) && changed ==true){
              
              // date de la premiere facture superieur à celle de création du membre 
              if( moment(d).isAfter(date.toString()) && changed==true ){

                  // si il a été crée avant ce mois ci 
                  if ( moment(date.toString()).isBefore(deb.toString()) && changed==true){

                      this.nbrEssMbrB++;
                      
                      let aux = this.listMember[uid];
                      this.listNewMemberB.push(aux);
                      return d; 
                  }
                  // si il a été cree ce mois ci !! pas  le meme jour 
                  else if(moment(date.toString()).isBetween(deb.toString(), fin.toString()) || moment(date.toString()).isSame(deb.toString()) || moment(date.toString()).isSame(fin.toString()) && changed==true ){
                      this.nbrEssMbrN++;
                      let aux = this.listMember[uid];
                      this.listNewMember.push(aux);
                      return d; 
                  }
                  
                  
              }
              // si la date de la premiere facture est la meme
              else if (moment(d).isSame(date.toString()) && changed==true){
                let h = moment( creat, "").format("HH:mm"); // creation membre 
                let hF = moment(factFirst.createAt,"").format("HH:mm")  // creation facture 
                let hs = h.split(":");
                let hfs = hF.split(":");

                if (parseInt(hs[0])>=parseInt(hfs[0])){
                  //si l'heure est superieur ou la meme , on regarde les minutes 
                  if ( parseInt(hs[1])>parseInt(hfs[1])){
                    this.nbrEssMbrN++;
                    let aux = this.listMember[uid];
                    this.listNewMember.push(aux);
                    return d;
                  }
                }


              }
              else{
                return null;
              }
              

          }
          else{
            return null;
          }
          


      },
      // nvx membre / tt les essais 
      // permet de calculer le taux annuel de transformation
      async getAnnuelNumber(moyenne){
        if (moyenne==true){
          this.reinitialize2();
          
          // cette liste permet de ne pas recompter plusieurs fois les essais 
          // compté dans l'année
          var listUid=[];


          for (let i=0; i<=11; i++){
           
             for (let j in this.listMember){
               let c = this.listMember[j].uid;
               let aux = await this.getNumberMonth(i, c, listUid );
                if ( aux !=null && aux !=-1 && aux !=2){
                    // s'il a été transformé 
                    this.nbYm++;
                    this.nbYess++;
                }
                else if ( aux==-1){
                  // s'il a été transformé 
                  this.nbYm++;
                  this.nbYess++;
                }
                else if ( aux==2){
                  // si c'est un essai 
                  this.nbYess++;
                }
                
                
                
    


               
             }
            
             
          }
          this.cal=false;
        

          // PB LE GRAPHE DU BAS PRENDS AUSSI DES MEMBRES POUR LES SEANCES ESSAI 

          if (this.nbYess==0 )
            return 0;
          else 
            return this.moyenne = parseInt((this.nbYm/(this.nbYess))*100);


        }
        else{
          return this.moyenne;
        }
         
      },
      changedcharge(val){
          this.oncharge=val;
          this.$emit('updateCharge', val)
      }, 
      // permet de calculer pour chaque mois de l'année 
      async getNumberMonth(mois, uid, listUid){
        let firstday = new Date(this.date.getFullYear(), mois, 1);
        let deb = moment(firstday,"").format("YYYY-MM-DD")
        let lastDay = new Date(this.date.getFullYear(), mois+1, 0);
        let fin = moment(lastDay,"").format("YYYY-MM-DD")
        var factFirst={};
        var d = moment(new Date(),"").format("YYYY-MM-DD").toString();
        
        var changed = false; 
        
          // for(let i in this.listItem){
          //   let aux = this.listItem[i];

           

          //   // on recupere les factures de chaque individus 
          //   if ( aux.member == uid && aux.credit >0){
          //       let create = moment(aux.createAt,"").format("YYYY-MM-DD")
          //       if ( moment(create.toString()).isBefore(d) || moment(create.toString()).isSame(d)  ){
          //           changed=true;
          //           d= create.toString();
          //           factFirst=aux;
          //       }

          //   }
           
          // }


          let first = await Item.AwaitGetAllMemoryKey(uid)
          if (first){
          
            for ( let i in first){
              let j = new Date(first[i].createAt).getDate()
              let m = new Date(first[i].createAt).getMonth()+1
              let y = new Date(first[i].createAt).getFullYear()
              
              let createa = y+"-"+m+"-"+j
              let create = moment(createa,"YYYY-MM-DD").format("YYYY-MM-DD")
                  // on regarde si la date de la facture correspont à la premiere facture 
                  if ( moment(create.toString()).isBefore(d) || moment(create.toString()).isSame(d)  ){
                      
                      changed=true;
                      d= create.toString();
                      factFirst=first[i];
                    
                  }
                  
                  
                  
            }
          }
          

            let datee = moment(this.listMember[uid].createAt,"").format("YYYY-MM-DD");
            let creat = this.listMember[uid].createAt;
          if ( moment(d).isBetween(deb.toString(), fin.toString()) || moment(d).isSame(deb.toString()) || moment(d).isSame(fin.toString()) && changed ==true){
              
              // date de la premiere facture superieur à celle de création du membre 
              if( moment(d).isAfter(datee.toString()) && changed==true ){

                  // si il a été crée avant ce mois ci 
                  if ( moment(datee.toString()).isBefore(deb.toString()) && changed==true){

                     this.listTr.push(uid)
                      return -1; 
                  }
                  // si il a été cree ce mois ci !! pas  le meme jour 
                  else if(moment(datee.toString()).isBetween(deb.toString(), fin.toString()) || moment(datee.toString()).isSame(deb.toString()) || moment(datee.toString()).isSame(fin.toString()) && changed==true ){
                      this.listTr.push(uid)
                      return 1; 
                  }
                  
              }
              // si la date de la premiere facture est la meme
              else if (moment(d).isSame(datee.toString()) && changed==true){
                let h = moment( creat, "").format("HH:mm"); // creation membre 
                let hF = moment(factFirst.createAt,"").format("HH:mm")  // creation facture 
                let hs = h.split(":");
                let hfs = hF.split(":");

                if (parseInt(hs[0])>=parseInt(hfs[0])){
                  //si l'heure est superieur ou la meme , on regarde les minutes 
                  if ( parseInt(hs[1])>parseInt(hfs[1])){
                    this.listTr.push(uid)
                    return 1;
                  }
                }


              }
              
              else{
                return null;
              }

              /////////////////

          
          

          }
          else if ( (moment(creat).isBetween(deb.toString(), fin.toString()) || moment(creat).isSame(deb.toString()) || moment(creat).isSame(fin.toString()) && changed ==false ) && listUid.indexOf(uid)==-1 && this.listMember[uid].type=='e' ){

                if ( this.listMember[uid].createAt.getFullYear()==this.date.getFullYear()){
                 
                    listUid.push(uid)
                    return 2;
                  
                }
                
              
            
          
          }

          
          

      },
      // permet de remplir le graphe 
      async fixeData(){
        
        //let pourcentageB=0;
        let pourcentageN=0;
        if( this.nbrEss!=0){
          pourcentageN = parseInt(( this.nbrEssMbrN /this.nbrEss)*100);
          //pourcentageB = parseInt(( this.nbrEssMbrB /this.nbrEss)*100);
        
        }

        this.default = pourcentageN;
        

        let pourcentageessai =  this.nbrEss;
        let pourcentagemembre= this.nbrEssMbrN;
        let list ={
          essai:pourcentageessai,
          clients : pourcentagemembre,

        }

        this.tablepourcentage.push(list);

        let ser =[];
        let moy = await  this.getAnnuelNumber(this.cal);
        
        
        //ser.push(pourcentageB);
        
        ser.push(moy);
        
        if( this.nbrEss==0 && this.nbrEssMbrN==0 && this.listNewMemberB==0 && this.listNewMember==0 ){
            ser.push(0);
            
        }
        else{
          
          ser.push(pourcentageN);
        }


        this.radialBarChart.series=ser;
        this.radialBarChart.chartOptions.labels.pop();
        this.radialBarChart.chartOptions.labels.pop();
        this.radialBarChart.chartOptions.labels.push(" Annuel ");
        
        this.radialBarChart.chartOptions.labels.push(" < 30 jours ");
        
        // this.oncharge=false;

         this.changedcharge(false);

      //  this.$emit('updateCharge', this.oncharge)
          
      }

  }
 
}
</script>

<style lang="scss" scoped>

th{
  text-align: center;
}

.leftB{
  border-right: solid 0.5px grey;
}

table{
  border-collapse: collapse;
}

.pop{
   color:#5b3cc4;
  }

.under{
  text-decoration: underline;
}

@media only screen and (min-width: 900px)
{
    .card_adjust{
        margin-right:10px;
    }
}
@media only screen and (max-width: 899px)
{
    .card_adjust{
        margin-left:0px;
    }
    .space{
        padding-bottom:10px;
    }
}
.text-spanR{
    background:rgba(255, 161, 140, 0.5);
    border-radius:5%;
    height: auto;
    width: auto;
    line-height: 26px;
    display: inline-block;
    text-align: center;
    margin-right: 6px;
    filter: drop-shadow(0 0 0.75rem #28C76F1F);
}
.text-spanG{
    
    background:rgba(166, 255, 140, 0.5) ;
    border-radius:5%;
    height: auto;
    width: auto;
    line-height: 26px;
    display: inline-block;
    text-align: center;
    color:hsl(113, 43%, 24%);
    margin-right: 6px;
    filter: drop-shadow(0 0 0.75rem #28C76F1F);
}


  .but:hover{
    background: #FFA18C;
  }
  .vs-popup--content {
    width: 95% !important;
    padding: 0%;
    font-size: 1rem;
    
  }

.panel_all_member
{
	.dropdown-button-container {
	margin-right:5px;
	display: flex;
	align-items: center;

	.btnx {
		border-radius: 5px 0px 0px 5px;
	}
	.btnx2 {
		border-left: 1px solid rgba(255, 255, 255, .2);	
		border-radius: 0px 0px 0px 0px;
	}

	.btn-drop {
    display: flex;
		border-radius: 0px 5px 5px 0px;
		border-left: 1px solid rgba(255, 255, 255, .2);
    
	}
	}
	.btn_special
	{
		font-weight: bold;
		margin-right:5px;
		padding:20px;
		padding-left:40px;
		padding-right:40px;
		color:white;
		border-radius: 5px;
		font-size:1.2em;
		cursor:pointer;

		border:2px;
		span{
			text-align:left;
		}
		b{
			float:right;
		}
	}
	th .vs-checkbox
	{
		display:none;
	}

	@media only screen and (max-width: 550px)
	{
		//Probléme de responsivité si le menu recherche reste
		.vs-table--search
		{
			display:none;
		}
	}

  

  
}






  


</style>