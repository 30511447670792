<template>
    <vue-apex-charts ref="donut" type="donut" :height="height" :options="donutChart.chartOptions" :series="donutChart.series"></vue-apex-charts>
</template>

<style lang="scss" scoped>

</style>

<script>
import VueApexCharts from 'vue-apexcharts'
import Member from '@/database/models/member'

export default {
    components:{
        VueApexCharts
    },
    props:['height'],
    data(){
        return{
            themeColors: [ Member.color.essai, Member.color.contractuel, Member.color.actif ],
            donutChart:
            {
                series: [0, 0, 0],
                chartOptions:
                {
                    
                    labels: ["Essai", "Contractuelle", "Membre"],
                    colors: [ Member.color.essai, Member.color.contractuel, Member.color.actif ],
                    responsive: [
                    {
                        breakpoint: 480,
                        options:
                        {
                            legend:
                            {
                                show: false,
                                position: 'bottom'
                            },
                        }
                    }],
                    legend:{
                        show: false,
                    },
                },
            }
        }
    },
    watch:{
        $route (){
            
        },
    },
    mounted:function()
	{
    },
    methods:{
        setEssai( value )
        {
            this.donutChart.series[0] = value;
        },
        setContractuel( value )
        {
            this.donutChart.series[1] = value;
        },
        setMember( value )
        {
            this.donutChart.series[2] = value;
        },


        addEssai( value )
        {
            this.donutChart.series[0] += value
        },
        addContractuel( value )
        {
            this.donutChart.series[1] += value;
        },
        addMember( value )
        {
            this.donutChart.series[2] += value;
        },

        refresh()
        {
            this.$refs.donut.refresh();
        }
    }
}

</script>