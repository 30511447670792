<template>
    <vx-card>
        <h2> Evolution annuelle {{ tyear }}</h2>
        <br/>
        <vue-apex-charts ref="charts" type="bar" height="350" :options="columnChart.chartOptions" :series="columnChart.series"></vue-apex-charts>
    </vx-card>
</template>

<style lang="scss" scoped>

</style>

<script>
import VueApexCharts from 'vue-apexcharts'
import Member from '@/database/models/member'
import Booking from '@/database/models/booking'

export default {
    components:{
        VueApexCharts
    },
    props:['year'],
    data(){
    return {
      tyear : this.year,

      themeColors: [ Member.color.essai, Member.color.actif ],
      columnChart: {
        series: [{
            name: 'Essai',
            type:'column',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }, {
            name: 'Membre',
            type:'column',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          } ,{
            name:' Membre+Essai',
            type:'line',
            data: [0, 0, 0, 0, 56, 0, 0, 0, 0, 0, 0, 0]
          }],


        chartOptions:
        {
          colors: [ Member.color.essai, Member.color.actif ],
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },

          xaxis: {
            categories: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          yaxis: {
            title: {
              show: false,
              text: ""
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val){
                return val + " réservation(s)"
              }
            }
          }
        }
      }
    }
  },
  mounted()
  {
      this.update();
  },
  methods:{
      update()
      {
        //this.columnChart.series[0].data[2] = 55
        Booking.getTabAllMemory((list)=>
        {
            //reset
            this.columnChart.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            this.columnChart.series[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]


            for( var i=0; i<list.length; i++)
            {
                var buffer = list[i];
                var da = new Date(buffer.date)
                if( da.getFullYear() == this.tyear )
                {
                    let month = da.getMonth();
                    switch (buffer.type)
                    {
                        case 'e':
                            this.columnChart.series[0].data[month]++;
                        break;
                        case 's':
                            this.columnChart.series[1].data[month]++;
                        break;
                    }
                }
            }
            
            this.$refs.charts.refresh();
        });
      },


    setYear( value )
    {
        this.tyear = value
    }

  }
}
</script>