<template>
	<div>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="5">
                <StatBookingAuj ref="StatBookingAuj" />
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="7">
                <StatLog ref="StatLog" />
            </vs-col>
        </vs-row>
        <br/>
        <StatBookingDonut ref="statBookingDonut" />
        <br/>
        <StatEssaiPro ref="statEssaiPro" v-bind:month="select_date.getMonth()" />
        <!-- <br/>
         <StatBookDay ref="statBookDay" v-bind:year="select_date.getFullYear()" v-bind:month="select_date.getMonth()"/> 
        <br/>-->
         <br/>
         
        
        <br/>
        <!-- <vs-row  vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
          <vs-col   vs-align="center" vs-xs="12" vs-sm="12" vs-lg="5">
                <StatMemberMonth ref="statTransfMonth"  v-bind:datee="select_date"></StatMemberMonth>
            </vs-col>
            <vs-col   vs-align="center"  vs-xs="12" vs-sm="12" vs-lg="7">
                <StatMemberYear ref="statTransfYear"  v-bind:datee="select_date"></StatMemberYear>
            </vs-col>
            <vs-button @click="UpdateTaux"> Mettre à jour </vs-button>
        </vs-row> -->

        <Taux ref="taux"/>
        <br/>
        <vs-row>
            <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="10">
                <cDate month="1" v-model="select_date" class="w-full select-large"/> 
                
            </vs-col>
            <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="2">
                <vs-button @click="UpdateTaux"> Mettre à jour </vs-button>
            </vs-col>
        </vs-row>
        
        <br/>
        <!-- <StatBookYear ref="statBookYear" v-bind:year="select_date.getFullYear()" /> -->
        <graphEnd ref="statBookYear" v-bind:year="select_date.getFullYear()" />
		
	</div>
</template>

<style lang="scss" scoped>

</style>


<script>
import cDate from '@/components/app/date'
import StatEssaiPro from '@/components/stats/pessai.vue'
import StatBookYear from '@/components/stats/bookingYear.vue'
import StatBookDay from '@/components/stats/bookingDay.vue'

import StatLog from '@/components/stats/log.vue'
import StatMemberMonth from '@/components/stats/components/graphAccueil/statTransfMonth.vue'
import StatMemberYear from '@/components/stats/components/graphAccueil/statTransfYear.vue'
import Taux from '@/components/stats/components/graphAccueil/tauxT.vue'
import graphEnd from '@/components/stats/components/graphAccueil/graphEnd.vue'
import StatBookingAuj from '@/components/stats/BookingAuj.vue'
import StatBookingDonut from '@/components/stats/bookingDonut.vue'
export default {
    components:{
        cDate,
        StatEssaiPro,
        StatBookYear,
        StatBookDay,
        StatLog,
        StatBookingAuj,
        StatBookingDonut,
        StatMemberMonth,
        StatMemberYear,
        graphEnd,
        Taux
    },
    data(){
        return{
            select_date : new Date(),
            
        }
    },
    watch:{
        select_date()
        {
            this.UpdateStatsEssai()
            this.UpdateStatsBookYear();
            // this.UpdateStatsBookDay();
            this.UpdateStatTransformationMonth();
        },
    },
    methods:{
        UpdateStatsEssai()
        {
            this.$refs.statEssaiPro.setMonth( this.select_date.getMonth() );
            this.$refs.statEssaiPro.setYear( this.select_date.getFullYear() );
            this.$refs.statEssaiPro.refresh();
        },

        UpdateStatsBookYear()
        {
            this.$refs.statBookYear.setYear( this.select_date.getFullYear() );
            this.$refs.statBookYear.update();
        },

        UpdateStatsBookDay()
        {
            this.$refs.statBookDay.setMonth( this.select_date.getMonth() );
            this.$refs.statBookDay.setYear( this.select_date.getFullYear() );
            this.$refs.statBookDay.update();
        },

        UpdateStatTransformationMonth(){
            // this.$refs.statTransfMonth.setDate(this.select_date)
            // this.$refs.statTransfYear.setDate(this.select_date)
            this.$refs.taux.UpdateStatTransformationMonth(this.select_date)
        },

         UpdateTaux(){
             this.$refs.taux.UpdateTaux(this.select_date)
            
         }
    }
};


//import Vue from 'vue'
//Vue.prototype.$bddRefresh.actualise();


</script>