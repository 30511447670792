<template>
  <div>
      
       <p align="center"> chargement en cours...</p>
        <br/>
          <div align="center">
            <h4 > Le saviez-vous ? </h4>
          </div>
      
      <br/>
      <div class="carousel-example">
        <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
            <swiper-slide>
              <img class="responsive" src="@/assets/images/waiting/pause1.png" alt="banner">
            </swiper-slide>
            <swiper-slide>
              <img class="responsive" src="@/assets/images/waiting/pause2.png" alt="banner">
            </swiper-slide>
            <!-- <swiper-slide>
              <img class="responsive" src="@/assets/images/pages/carousel/banner-4.jpg" alt="banner">
            </swiper-slide>
            <swiper-slide>
              <img class="responsive" src="@/assets/images/pages/carousel/banner-2.jpg" alt="banner">
            </swiper-slide> -->
            <div class="swiper-pagination" slot="pagination"></div>
            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
    </div>
      

  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    components: {
            swiper,
            swiperSlide
    },
    data() {
        return {
            swiperOption: {
                spaceBetween: 30,
                centeredSlides: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    }
    

}
</script>

<style>

</style>